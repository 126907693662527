<template>
    <div :id="blokId(blok)" class="text-center align-middle flex flex-col">
        <div class="text-center">
            <template v-if="richContentText">
                <a v-if="imageLink" :href="imageLink" target="_blank">
                    <div v-html="richContentText" />
                </a>

                <div v-else v-html="richContentText" />
            </template>
        </div>
        <ButtonContainer :buttons="blok.buttons" />
    </div>
</template>

<script setup lang="ts">
import { richTextResolver } from '@storyblok/richtext'
import ButtonContainer from '~/components/Buttons/ButtonContainer.vue'
import { blokId } from '~/utils/functions'

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
})

const richContentText = computed(() =>
    richTextResolver().render(props.blok.content),
)

const imageLink = computed(
    () =>
        props.blok.content?.content?.[0]?.content?.[0]?.marks?.[0]?.attrs
            .href ?? null,
)
</script>

<style>
img {
    @apply !inline-block;
}
</style>
